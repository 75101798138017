import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import {
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  InMemoryScrollingFeature,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withViewTransitions
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  MissingTranslationHandler,
  provideTranslateService,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthHttpService } from '@shared/data';
import { MissingTranslationHelper, SentryErrorHandler } from '@shared/handlers';
import { authInterceptor } from '@shared/interceptors';
import { AppInitializeService } from '@shared/services';
import { getPreferredLanguage } from '@shared/utils';
import { en_GB, provideNzI18n } from 'ng-zorro-antd/i18n';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling({
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  });

const httpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(httpClient);

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNzI18n(en_GB),
    importProvidersFrom(BrowserModule),
    provideTranslateService({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper
      },
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    provideRouter(
      routes,
      withComponentInputBinding(),
      inMemoryScrollingFeature,
      withViewTransitions()
    ),
    AuthHttpService,
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    provideAppInitializer(() => inject(AppInitializeService).init()),
    {
      provide: LOCALE_ID,
      useFactory: getPreferredLanguage
    },
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
}).catch(err => console.error(err));
