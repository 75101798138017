import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { captureException, init, replayIntegration } from '@sentry/angular';
import { ApplicationFacade, AuthHttpService } from '@shared/data';
import { EnvironmentService, NotificationService } from '@shared/services';
import { stillHasValidToken } from '@shared/utils';

interface ErrorMessage {
  error: string;
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private readonly authService = inject(AuthHttpService);
  private readonly appFacade = inject(ApplicationFacade);
  private readonly notificationService = inject(NotificationService);
  private readonly translateService = inject(TranslateService);
  private readonly environment = inject(EnvironmentService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);

  constructor() {
    init({
      dsn: 'https://e1059e0ae91b4d2546d2d20d92191be1@o4506836110082048.ingest.sentry.io/4506836695711744',
      release: 'moxi-ui@1.0.0',
      environment: 'production',
      integrations: [
        // Registers the Replay integration,
        // which automatically captures Session Replays
        replayIntegration()
      ],

      ignoreErrors: [
        'NG0953',
        'NG0750',
        'tDetails.loadingPromise(@angular/core/fesm2022/core)',
        'Non-Error promise rejection captured',
        'Non-Error exception captured',
        'UnhandledRejection',
        'The Google Maps JavaScript API could not load',
        'IconNotFoundError',
        '@ant-design/icons-angular',
        'unknown',
        'Object captured as promise rejection with keys: details, message, status',
        '[@ant-design/icons-angular]',
        'InvalidStateError startViewTransition([native code])',
        'startViewTransition([native code])',
        'InvalidStateError',
        'View transition was skipped because document visibility state is hidden.',
        'Old view transition aborted by new view transition',
        'AbortError',
        'Importing a module script failed.',
        'Failed to fetch dynamically imported module',
        'TypeError: error loading dynamically imported module',
        'error loading dynamically imported module',
        'Skipping view transition because viewport size changed.',
        'ZoneImpl.runTask',
        'Object captured as exception with keys: error, headers, message, name, ok'
      ],

      denyUrls: ['https://translate.googleapis.com'],

      // enable Sentry in production only
      enabled: this.environment.production,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.5
    });
  }

  handleError(error): void {
    // Handle Unexpected token error by reloading the page
    this.handleUnexpectedTokenError(error);

    // Check if it's an error from an HTTP response
    if (error instanceof HttpErrorResponse) {
      let type: 'error' | 'warning' = 'error';
      let title = 'notifications.errorTitle';
      let message = (error?.error as ErrorMessage)?.error ?? error.statusText;

      if (typeof error?.error === 'string') {
        try {
          message = (JSON.parse(error.error) as ErrorMessage)?.error;
        } catch {
          message = error.error;
        }
      }

      // This is a special case where the user is logged in but the token has expired
      if (
        error?.status === 401 &&
        this.appFacade.isLoggedIn() &&
        !stillHasValidToken()
      ) {
        type = 'warning';
        message = 'notifications.sessionTimeout.message';
        title = 'notifications.sessionTimeout.title';

        this.authService.signOut();
      }

      if (error.status === 403) {
        this.router.navigate(['/account']);
      }

      // TODO: this replace is a workaround because some error messages
      // are phrases with full stops and the i18n keys can't have dots
      const i18nMessage = this.translateService.instant(
        `notifications.serverErrors.${message?.replace(/\./g, '')}`,
        { default: message }
      );
      this.notificationService[type](i18nMessage, null, title);

      if (!this.environment.production) {
        console.error(error);
      }

      return;
    }

    console.error(error);
    captureException(error, { user: { ip: null } });
  }

  /**
   * Handles errors related to unexpected tokens in the application.
   * Specifically checks for the presence of "Unexpected token '<'" error,
   * which often indicates a network or parsing issue with the received content.
   * If this specific error is detected, the method triggers a page reload
   * to attempt recovery.
   *
   * @param error - The error object to check
   * @private
   */
  private handleUnexpectedTokenError(error: unknown): void {
    if (
      String(error).includes(`expected expression, got '<'`) ||
      String(error).includes(`Unexpected token '<'`)
    ) {
      this.document.location.reload();
    }
  }
}
