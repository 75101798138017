/* eslint-disable @softarc/sheriff/encapsulation */
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@home/shell'),
    loadChildren: () => import('@home').then(r => r.routes)
  },
  {
    path: 'auth',
    loadComponent: () => import('@authentication/shell'),
    loadChildren: () => import('@authentication').then(r => r.routes)
  },
  {
    path: 'mobile-notification',
    loadComponent: () =>
      import('./shared/ui/mobile-notification/mobile-notification.component')
  },
  {
    path: '**',
    redirectTo: '/'
  }
];
